import { useApolloClient, useMutation, gql, useLazyQuery } from "@apollo/client"
import React, { useState, useRef, useContext, useEffect, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { fragments } from "@/graphql/fragments"
import { SlideModalContext } from "@/hooks/SlideModalHook"
import SlideDropdownList from "@/components/slide/SlideDropdownList"
import SlidePlaceholder from "./SlidePlaceholder"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Menu } from "@headlessui/react"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"
import { getSelectedSlideID, insertSlideInPowerPoint, selectInsertedSlide } from "@/components/utils/powerpoint/add-in"
import { CategoryContext } from "@/context/CategoryContext"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import useModal from "@/hooks/useModal"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

const SLIDE_DOWNLOAD_URL = gql`
  query slideDownloadUrl($slideId: String!, $presentationName: String!) {
    slideDownloadUrl(slideId: $slideId, presentationName: $presentationName)
  }
`

const GET_THUMB_AND_LOCK = gql`
  query slide($slideId: String!) {
    slide(slideId: $slideId) {
      id
      thumbUrl
      lock
      state
      phash
      updatedAt
    }
  }
`

const REMOVE_SLIDE = gql`
  mutation removeSlide($presentationId: String!, $slideId: String!) {
    removeSlide(presentationId: $presentationId, slideId: $slideId) {
      code
      success
      message
      job {
        id
        queueName
      }
    }
  }
`

const SET_SLIDE_FAVOURITE = gql`
  mutation setSlideFavourite($slideId: String!, $state: Boolean!) {
    setSlideFavourite(slideId: $slideId, state: $state) {
      id
      slideId
      isFavourite
    }
  }
`

const Slide = ({
  batch,
  batchType,
  handleMergeJobStatus,
  setPresentationThumbUrl,
  index,
  noActions,
  refetch,
  slide,
  isSearchPage,
  handleSelectSlide,
  isSelected,
  isSelectMode
}) => {
  const { id, slideId, blueprintId, thumbUrl, isFavourite, phash } = slide
  const location = useLocation()
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const isFavoritesPath = location.pathname.split("/")[1] === "favorites"
  const { openModal: openSlideModal } = useContext(SlideModalContext)
  const {
    updateSlidesInCartFunction,
    user: { isEditModeActive, cart }
  } = useContext(UserDataContext)
  const { replaceDuplicateSlides } = useContext(CategoryContext)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [isReplacing, setIsReplacing] = useState(slide.state === "locked")
  const [currentThumbUrl, setCurrentThumbUrl] = useState(thumbUrl)
  const [isPreviouslyLocked, setIsPreviouslyLocked] = useState(false)
  const [previousState, setPreviousState] = useState(slide.state)
  const [isAddSlideLoading, setIsAddSlideLoading] = useState(false)
  const { slideId: slideIdParam } = useParams()
  const slideContainerRef = useRef(null)
  const [slidePhash, setSlidePhash] = useState(phash)
  const [addSlideDisabled, setAddSlideDisabled] = useState(false)
  const { isOpen: isModalOpen, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()

  const client = useApolloClient()
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: !isEditModeActive || (isSelectMode && !isSelected),
    data: {
      thumbUrl: currentThumbUrl
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  useEffect(() => {
    if (isInsidePowerPoint) {
      setAddSlideDisabled(!!(slide.state !== "uploaded" || isAddSlideLoading))
    }
  }, [slide.state, isAddSlideLoading])

  const [setSlideFavourite] = useMutation(SET_SLIDE_FAVOURITE, {
    context: { isUsingNewScApi: true }
  })
  const [removeSlide] = useMutation(REMOVE_SLIDE, {
    context: { isUsingNewScApi: true },
    onCompleted: (data) => {
      const { queueName, id: jobId } = data.removeSlide.job

      handleMergeJobStatus(queueName, jobId)
    }
  })

  const [getSlideDownloadUrl] = useLazyQuery(SLIDE_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })

  const [getThumbAndLock, { stopPolling }] = useLazyQuery(GET_THUMB_AND_LOCK, {
    notifyOnNetworkStatusChange: true,
    context: { isUsingNewScApi: true },
    pollInterval: 1000,
    fetchPolicy: "network-only",
    onCompleted: ({ slide }) => {
      const isSlideLocked = slide.state === "locked"
      if (
        slide.lock === null &&
        slide.state === "uploaded" &&
        (previousState === "finalizing" || previousState === "locked")
      ) {
        setSlidePhash(slide.phash)
        stopPolling()
      }
      if (
        (isPreviouslyLocked === true || previousState === "finalizing") &&
        slide.lock === false &&
        slide.state === "uploaded"
      ) {
        stopPolling()
      }

      if (index === 0 && setPresentationThumbUrl && !isSlideLocked) setPresentationThumbUrl(slide.thumbUrl)
      if (!isSlideLocked) setCurrentThumbUrl(slide.thumbUrl)
      setIsPreviouslyLocked(slide.lock)
      setPreviousState(slide.state)

      switch (slide.state) {
        case "locked":
          setIsReplacing(true)
          break
        case "finalizing":
          if (!isSearchPage && !isFavoritesPath) {
            refetch()
          }
          setIsReplacing(false)
          break
        case "uploaded":
          refetch()
          setIsReplacing(false)

          // Update the presentation`s thumbUrl
          batch &&
            client.writeFragment({
              id: batch.id,
              fragment: gql`
                fragment UsersBatchFields on UsersBatch {
                  thumbUrl
                }
              `,
              data: { thumbUrl }
            })
          // (typeof slide.lock) will be boolean when we are editing slide in PowerPoint, so that time we should replace the same slides
          if (typeof slide.lock === "boolean" && previousState === "finalizing") {
            replaceDuplicateSlides({ ...slide, oldPhash: slidePhash })
            setSlidePhash(slide.phash)
          }

          break
      }
    }
  })

  useEffect(() => {
    if (slide.lock && slide.state === "uploaded") {
      getThumbAndLock({ variables: { slideId: slide.blueprintId } })
    }
  }, [slide.lock])

  const imgRef = useRef()
  const imageSource = currentThumbUrl.replace("{width}", 208)
  const imageSourceSet = currentThumbUrl.replace("{width}", 416)

  useEffect(() => {
    if (slideIdParam === slide.id) {
      const element = slideContainerRef.current
      if (element) {
        const topOffset = element.getBoundingClientRect().top + window.scrollY - 20
        window.scrollTo({ top: topOffset, behavior: "smooth" })
      }
    }
  }, [slideIdParam])

  const handleDropdown = () => setDropdownIsOpen(!dropdownIsOpen)

  const onOpenModal = ({ position, e }) => {
    e.preventDefault()
    openSlideModal({ position, slides: batch.slides, pId: batch && batch.batchId, downloadSlide })
  }

  const downloadSlide = async () => {
    const { data } = await getSlideDownloadUrl({
      variables: {
        slideId: blueprintId,
        presentationName: batch && batch?.name ? batch.name : "Favourite"
      }
    })

    handleDropdown()
    if (data.slideDownloadUrl) window.location.href = data.slideDownloadUrl
  }

  const updateSlideInCart = async () => {
    if (cart) {
      const items = [...cart.slides]
      items.push(slide)
      await updateSlidesInCartFunction(items)
    } else {
      const items = [slide]
      await updateSlidesInCartFunction(items)
    }
  }
  const handleLike = async () => {
    await setSlideFavourite({
      variables: {
        slideId: blueprintId,
        state: !isFavourite
      },
      update: (cache, { data: { setSlideFavourite } }) => {
        if (!setSlideFavourite) {
          return
        }
        const slide = client.readFragment({
          id: blueprintId,
          fragment: fragments.presentationSlide
        })

        if (slide && slide.id) {
          const updatedSlide = {
            ...slide,
            isFavourite: setSlideFavourite.isFavourite
          }
          client.writeFragment({
            id: blueprintId,
            fragment: fragments.presentationSlide,
            data: updatedSlide
          })
          return
        }
        const favouriteSlide = client.readFragment({
          id: blueprintId,
          fragment: fragments.favouriteSlide
        })
        if (favouriteSlide && favouriteSlide.id) {
          const updatedSlide = {
            ...favouriteSlide,
            isFavourite: setSlideFavourite.isFavourite
          }
          client.writeFragment({
            id: blueprintId,
            fragment: fragments.favouriteSlide,
            data: updatedSlide
          })
          return
        }
      },
      optimisticResponse: {
        setSlideFavourite: {
          __typename: "SetSlideFavouriteResponse",
          slideId,
          id,
          isFavourite: !isFavourite
        }
      }
    })
  }

  const deleteSlide = () => {
    removeSlide({
      variables: { presentationId: batch.batchId, slideId: blueprintId },
      update: (proxy) => {
        const deletedSlideIdx = batch.slides.findIndex((_slide) => _slide.blueprintId === blueprintId)
        proxy.writeFragment({
          id: batch.id,
          fragment: fragments.presentation,
          data: {
            ...batch,
            slides: batch.slides.filter((_slide) => _slide.blueprintId !== blueprintId),
            ...(batch.mode !== "blueprints" && batch.slides.length > 1
              ? { thumbUrl: batch.slides[deletedSlideIdx === 0 ? 1 : 0].thumbUrl }
              : {
                  thumbUrl: ""
                })
            // BU: set empty gif if deleting last slide -^
          }
        })
        // refetch new slides after deletion
        refetch()
      }
    })
  }

  const highlightSlide = useMemo(() => {
    return !!(!isInsidePowerPoint && cart && cart.slides.find((slide) => slide.blueprintId === id))
  }, [cart, id])

  useEffect(() => {
    if (slide.state !== "uploaded") getThumbAndLock({ variables: { slideId: slide.blueprintId } })
  }, [slide.state])

  const addToPowerPointPresentation = async () => {
    setIsAddSlideLoading(true)
    try {
      await window.PowerPoint.run(async function (context) {
        context.presentation.slides.load()
        await context.sync()
        let selectedSlideID

        if (context.presentation.slides.items.length) {
          selectedSlideID = await getSelectedSlideID()
        }

        const { data } = await getSlideDownloadUrl({
          variables: {
            slideId: blueprintId,
            presentationName: batch?.name || ""
          }
        })

        await insertSlideInPowerPoint(data.slideDownloadUrl, selectedSlideID ? selectedSlideID + "#" : null)

        selectInsertedSlide(selectedSlideID)
        setIsAddSlideLoading(false)
      })
    } catch (error) {
      console.error("Error: " + error)
      setIsAddSlideLoading(false)
      throw error
    }
  }

  const handleAddSlide = async () => {
    // Add slide to PowerPoint presentation or add slide to cart
    if (isInsidePowerPoint) {
      await addToPowerPointPresentation()
    } else {
      await updateSlideInCart()
    }
  }

  const handleSelect = () => {
    handleSelectSlide(id)
  }

  return isReplacing ? (
    <div className="m-[6.25px] aspect-[344/193.5]">
      <SlidePlaceholder />
    </div>
  ) : (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <div
        className={`h-full relative transition ease-in-out duration-[200ms] ${
          isInsidePowerPoint ? "rounded-[7px]" : ""
        } ${!isInsidePowerPoint ? "m-0" : ""} ${
          highlightSlide
            ? `!opacity-[0.6] pointer-events-none ${!isSearchPage ? "scale-[.94]" : ""} `
            : "shadow-[0_0_12px_1px_#7a7f8733]"
        } ${isSelectMode ? "cursor-pointer" : ""}`}
        data-testid="slide"
        onClick={isSelectMode ? handleSelect : null}
      >
        <div
          className={`w-full h-full ${
            slide.id === slideIdParam
              ? `border-2 border-sc-blue ${isInsidePowerPoint ? "rounded-[7px]" : "rounded-[3px]"}`
              : ""
          } ${isDragging ? "hide" : ""}`}
          ref={slideContainerRef}
        >
          <picture>
            <img
              alt={slide.name}
              className={`w-full h-full object-cover aspect-[427/240.5] ${isInsidePowerPoint ? "rounded-[7px]" : ""}`}
              data-testid="slide-thumbnail"
              ref={imgRef}
              src={imageSource}
              srcSet={imageSourceSet}
            />
          </picture>
        </div>
        <div
          className={`absolute top-0 w-full h-full text-center group ${
            isSelectMode || dropdownIsOpen
              ? "bg-sc-overlay-light"
              : "hover:bg-sc-overlay-light hover:transition-colors hover:duration-300"
          } ${
            isInsidePowerPoint ? "border-2 border-transparent rounded-[7px] hover:border-sc-blue duration-200" : ""
          } ${dropdownIsOpen ? "links-collapsed" : ""} ${highlightSlide || isDragging ? "hidden" : ""}`}
        >
          <div
            className={`invisible opacity-0 ${
              !isSelectMode
                ? "group-hover:visible group-hover:opacity-100 group-hover:transition-opacity group-hover:duration-300"
                : ""
            } h-full flex justify-center items-center`}
          >
            <button
              className={`flex justify-center items-center text-[14px] rounded-[50px] transition-colors ease-in-out duration-200 text-sc-font-gray bg-white shadow-slack border border-sc-stroke ${
                isInsidePowerPoint
                  ? "w-[82px] h-[30px] px-[16px] py-[7px] !bg-scblue border-none text-white hover:!bg-[#136FD5]"
                  : "w-[141px] h-[38px] p-[7px] mobile-sm:w-[170px] mobile-sm:h-[38px] tablet-md:w-[141px] tablet-md:h-[38px] tablet-xl:w-[170px] tablet-xl:h-[38px] hover:bg-white hover:text-sc-blue"
              }`}
              data-testid="btn-add-to-cart"
              disabled={addSlideDisabled}
              onClick={handleAddSlide}
            >
              {isAddSlideLoading ? (
                <svg
                  aria-hidden="true"
                  className="inline w-4 h-4 text-white animate-spin"
                  fill="none"
                  role="status"
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : isInsidePowerPoint ? (
                "Add"
              ) : (
                "Add to cart"
              )}
            </button>
          </div>
          <div
            className={`absolute top-0 w-full py-[12px] px-[14px] justify-between ${
              isInsidePowerPoint ? "hidden" : "flex"
            }`}
          >
            <button
              className={`opacity-0 ${
                isSelectMode
                  ? "opacity-100 group-hover:text-[#0F2642] group-hover:bg-[#E9EAED]"
                  : "group-hover:opacity-100 group-hover:transition-opacity group-hover:duration-300"
              } w-[36px] rounded-[20px] flex justify-center items-center shadow-slack ${
                isSelected
                  ? "!text-white !bg-[#1683FB]"
                  : "bg-white text-sc-font-gray hover:text-[#0F2642] hover:bg-[#E9EAED] border border-sc-stroke"
              } transition-colors`}
              onClick={handleSelect}
            >
              <svg
                fill="none"
                height="20"
                stroke="currentColor"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 9.5L8.61538 14L16 6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.1" />
              </svg>
            </button>
            <div
              className={`${dropdownIsOpen ? "visible opacity-100" : "invisible opacity-0"} ${
                !isSelectMode
                  ? "group-hover:visible group-hover:opacity-100 group-hover:transition-opacity group-hover:duration-300"
                  : ""
              } flex gap-[8px] items-center justify-center text-sc-font-gray [&>button:hover]:text-sc-blue bg-white px-[12px] py-[8px] rounded-[50px] border border-sc-stroke shadow-slack`}
            >
              <button
                className="w-[20px] h-[20px] flex justify-center items-center transition-colors ease-in-out duration-200"
                data-testid="btn-preview"
                onClick={(e) => onOpenModal({ position: index, e })}
                type="button"
              >
                <svg
                  fill="none"
                  height="16"
                  stroke="currentColor"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Fether icons / zoom-in ">
                    <path
                      d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                      id="Vector"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.1"
                    />
                    <path
                      d="M13.9996 14L11.0996 11.1"
                      id="Vector_2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.1"
                    />
                    <path
                      d="M7.33301 5.33337V9.33337"
                      id="Vector_3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.1"
                    />
                    <path
                      d="M5.33398 7.33337H9.33398"
                      id="Vector_4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.1"
                    />
                  </g>
                </svg>
              </button>
              <button
                className={
                  "w-[20px] h-[20px] flex justify-center items-center transition-colors ease-in-out duration-200"
                }
                data-testid="btn-heart"
                onClick={handleLike}
              >
                <svg
                  className={`${
                    isFavourite ? "fill-sc-blue stroke-sc-blue animate-heart-beat" : "fill-transparent stroke-current"
                  }`}
                  data-testid={`${isFavourite ? "heart-beat" : "heart-unbeat"}`}
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Fether icons / heart ">
                    <path
                      d="M15.6382 5.42676C15.3124 5.11534 14.9256 4.8683 14.4999 4.69976C14.0741 4.53121 13.6178 4.44446 13.1569 4.44446C12.6961 4.44446 12.2397 4.53121 11.814 4.69976C11.3883 4.8683 11.0014 5.11534 10.6756 5.42676L9.9995 6.07276L9.32337 5.42676C8.66529 4.79801 7.77274 4.44478 6.84208 4.44478C5.91142 4.44478 5.01887 4.79801 4.36079 5.42676C3.70271 6.05551 3.33301 6.90828 3.33301 7.79746C3.33301 8.68665 3.70271 9.53942 4.36079 10.1682L5.03693 10.8142L9.9995 15.5556L14.9621 10.8142L15.6382 10.1682C15.9642 9.85689 16.2227 9.48731 16.3991 9.08054C16.5755 8.67377 16.6663 8.23777 16.6663 7.79746C16.6663 7.35715 16.5755 6.92116 16.3991 6.51439C16.2227 6.10761 15.9642 5.73803 15.6382 5.42676V5.42676Z"
                      id="Vector"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.1"
                    />
                  </g>
                </svg>
              </button>
              {/* Slide drop down for shared expanded group */}

              <Menu as="div" className={"w-[20px] h-[20px] relative flex justify-center"}>
                {({ open }) => {
                  useEffect(() => {
                    setDropdownIsOpen(open)
                  }, [open])

                  return (
                    <>
                      <Menu.Button
                        className="flex w-full h-full items-center justify-center hover:text-scblue"
                        data-testid="slide-context-menu-button"
                      >
                        <svg
                          fill="currentColor"
                          height="20"
                          viewBox="0 0 20 20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="1.28572"
                            cy="1.28572"
                            r="1.28572"
                            transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 12 16)"
                          />
                          <circle
                            cx="1.28572"
                            cy="1.28572"
                            r="1.28572"
                            transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 12 11.2858)"
                          />
                          <circle
                            cx="1.28572"
                            cy="1.28572"
                            r="1.28572"
                            transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 12 6.57141)"
                          />
                        </svg>
                      </Menu.Button>
                      <Menu.Items
                        className="absolute top-[32px] right-0 z-20 w-56 text-sm rounded-md bg-white shadow-lg ring-gray-300 focus:outline-none p-1"
                        id="contextMenuItems"
                      >
                        <SlideDropdownList
                          bartek={!noActions && isEditModeActive}
                          batchId={batch?.batchId || ""}
                          closeDropdown={handleDropdown}
                          deleteAvailable={
                            (!noActions && isEditModeActive) ||
                            batchType === "batches" ||
                            (isEditModeActive && batchType === "sharedBatches")
                          }
                          deleteSlide={deleteSlide}
                          downloadAvailable={slide.state === "uploaded"}
                          editInPowerPointAvailable={!isFavoritesPath && isEditModeActive}
                          getThumbAndLock={getThumbAndLock}
                          goToSlideAvailable={isSearchPage}
                          handleDownload={downloadSlide}
                          handleMergeJobStatus={handleMergeJobStatus}
                          isEditModeActive={isEditModeActive}
                          moveToAvailable={batch && batchType === "templates" && !noActions && isEditModeActive}
                          openConfirmationModal={openConfirmationModal}
                          refetch={refetch}
                          slide={slide}
                        />
                      </Menu.Items>
                      <ConfirmationModal
                        close={closeConfirmationModal}
                        confirmButtonText="Delete"
                        confirmFunction={deleteSlide}
                        context={<>Are you sure you want to remove 1 slide from SlideCamp?</>}
                        isOpen={isModalOpen}
                        mode="danger"
                        title="Delete Slide"
                      />
                    </>
                  )
                }}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slide
