import slugify from "slug"

export const getLibraryLink = (categories) => {
  if (categories?.length) {
    return `/library/${categories[0].urlToken}/${slugify(categories[0].name)}`
  }
  return "/library"
}

export const pluralize = (count, noun) => {
  return count === 1 ? noun : `${noun}s`
}

export const splitSnippet = (snippet) => {
  // Regex to match the content inside and around the <mark> tags
  const regex = /(<mark>.*?<\/mark>)/ // This regex captures everything inside the <mark> tags, including the tags themselves
  const charLimit = 15 // Define the character limit for slicing text before and after the <mark>

  // Split the snippet into three parts: text before <mark>, the mark content, and text after </mark>
  const [beforeText, markContent, afterText] = snippet.split(regex)

  // Replace <mark> tags with <b> tags in the marked content
  const bContent = markContent?.replace("<mark>", "<b>").replace("</mark>", "</b>") || ""

  // Determine the number of characters to display from before and after the mark based on the overall text length
  const beforeTextSpliceCharsCount =
    beforeText.length > charLimit && afterText.length < charLimit
      ? charLimit + (charLimit - afterText.length) // Adjust to show more characters from before if after is short
      : charLimit // Default to charLimit

  const afterTextSpliceCharsCount =
    afterText.length > charLimit && beforeText.length < charLimit
      ? charLimit + (charLimit - beforeText.length) // Adjust to show more characters from after if before is short
      : charLimit // Default to charLimit

  // Get the last 'charLimit' characters from beforeText, prepending ellipses if necessary
  const beforeChars =
    beforeText.length > beforeTextSpliceCharsCount
      ? `...${beforeText.slice(-beforeTextSpliceCharsCount)}` // Prepend ellipses if truncating
      : beforeText // If not truncating, return the whole beforeText

  // Get the first 'charLimit' characters from afterText, appending ellipses if necessary
  const afterChars =
    afterText.length > afterTextSpliceCharsCount
      ? `${afterText.slice(0, afterTextSpliceCharsCount)}...` // Append ellipses if truncating
      : afterText // If not truncating, return the whole afterText

  // Combine the sliced parts and marked content into a single formatted string and return it
  return `${beforeChars}${bContent}${afterChars}`
}

export function getTimestampFromObjectId(objectId) {
  // Get the first 8 characters (timestamp portion) of the ObjectId
  const timestampHex = objectId.substring(0, 8)
  const timestamp = parseInt(timestampHex, 16)
  const date = new Date(timestamp * 1000)

  return date
}

export function checkIsNewOrganization(id) {
  const validDate = new Date("2024-09-01T00:00:00Z") // UTC time
  const organizationCreatedAt = getTimestampFromObjectId(id)

  return organizationCreatedAt >= validDate
}

export function orderSelectedSlides(allSlides, selectedSlideIds) {
  return allSlides.reduce((orderedList, slide) => {
    if (selectedSlideIds.includes(slide.id)) {
      orderedList.push(slide.id)
    }
    return orderedList
  }, [])
}

function filterPresentationsByNameQuery(presentations, searchQuery) {
  return presentations.filter((presentation) => {
    return presentation.name.toLowerCase().includes(searchQuery)
  })
}

export function filterCategoryByPresentationNameQuery(category, searchQuery) {
  const filteredCollections = filterPresentationsByNameQuery(category.collections, searchQuery)
  const filteredPresentations = filterPresentationsByNameQuery(category.presentations, searchQuery)

  const filteredSubCategories = (category.subCategories || []).reduce((subCategories, subCategory) => {
    const filteredSubCategory = filterCategoryByPresentationNameQuery(subCategory, searchQuery)
    if (searchQuery.length) {
      if (filteredSubCategory.fullPresentationsLength) {
        subCategories.push(filteredSubCategory)
      }
    } else {
      subCategories.push(filteredSubCategory)
    }
    return subCategories
  }, [])

  return {
    ...category,
    collections: filteredCollections,
    presentations: filteredPresentations,
    subCategories: filteredSubCategories,
    fullPresentationsLength: filteredCollections.length + filteredPresentations.length + filteredSubCategories.length
  }
}

export function moveSlides(slides, fromIds, toId, activeId) {
  // Filter out the items that need to be moved
  const movingSlides = []
  const updatedSlides = slides.filter((slide) => {
    if (fromIds.includes(slide.id)) {
      movingSlides.push(slide) // Collect the slides to move
      return false // Remove this slide from the remaining array
    }
    return true // Keep the slide in the remaining array
  })

  const activeSlideIndex = slides.findIndex((slide) => slide.id === activeId)
  const overSlideIndex = slides.findIndex((slide) => slide.id === toId)

  let targetIndex = updatedSlides.findIndex((slide) => slide.id === toId)
  if (activeSlideIndex < overSlideIndex) {
    targetIndex++
  }
  //Insert moving slides into the remaining slides
  updatedSlides.splice(targetIndex, 0, ...movingSlides)
  return updatedSlides
}
