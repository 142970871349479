import { gql } from "@apollo/client"

export const TEAM_TAGS = gql`
  query teamTags {
    teamTags
  }
`

export const GET_SLIDE_TAGS_AND_TITLES = gql`
  query slide($slideId: String!) {
    slide(slideId: $slideId) {
      tags
      extractedTitles {
        titleFromMinicpm
        titleFromPixtral
        titleFromQwen
        titleFromMolmo
        titleFromLlama
      }
      details {
        model_id
        type
        value
      }
    }
  }
`

export const ADD_TAG = gql`
  mutation addTag($slideId: String!, $tag: String!) {
    addTag(slideId: $slideId, tag: $tag) {
      code
      success
      message
      tags
    }
  }
`

export const SET_TAGS = gql`
  mutation setTags($slideId: String!, $tags: [String!]!) {
    setTags(slideId: $slideId, tags: $tags) {
      code
      success
      message
      tags
    }
  }
`

export const REMOVE_TAG = gql`
  mutation removeTag($slideId: String!, $tag: String!) {
    removeTag(slideId: $slideId, tag: $tag) {
      code
      success
      message
      tags
    }
  }
`
