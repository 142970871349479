import { useContext, useEffect } from "react"
import { InactiveAccount } from "./InactiveAccount"
import { Instructions } from "./Instructions"
import MoveOrCopySlides from "@/components/modals/moveOrCopySlides/MoveOrCopySlides"
import EditCategory from "./editCategory/EditCategoryWrapper"
import AddNewTeam from "./AddNewTeam"
import RemoveUserFromUnit from "./RemoveUserFromUnit"
import AddUserToUnit from "./AddUserToUnit"
import RemoveUnit from "./RemoveUnit"
import SharePresentationModal from "@/components/modals/share/SharePresentationModal"
import InviteFriendForm from "./InviteFriendForm"
import RemoveUserForm from "./RemoveUserForm"
import DeleteAllPresentations from "./DeleteAllPresentations"
import DeleteAllSlides from "./DeleteAllSlides"
import ChangeMemberRole from "./ChangeMemberRole"
import { MainModalContext } from "@/hooks/MainModalHook"
import { useLocation } from "react-router-dom"
import InvitationLink from "@/components/modals/InvitationLink"
import { CancelIcon } from "@/svg/SvgIcons"

const contents = {
  inactiveAccount: (props) => <InactiveAccount {...props} />,
  instructions: (props) => <Instructions {...props} />,
  addNewCategory: (props) => <EditCategory {...props} type="add" />,
  editCategory: (props) => <EditCategory {...props} type="edit" />,
  SharePresentationModal: (props) => <SharePresentationModal {...props} />,
  addNewTeam: (props) => <AddNewTeam {...props} />,
  addUserToUnit: (props) => <AddUserToUnit {...props} />,
  removeUserFromUnit: (props) => <RemoveUserFromUnit {...props} />,
  removeUnit: (props) => <RemoveUnit {...props} />,
  addUserToTeam: (props) => <InviteFriendForm {...props} />,
  removeUserFromTeam: (props) => <RemoveUserForm {...props} />,
  changeMemberRole: (props) => <ChangeMemberRole {...props} />,
  showInvitationLink: (props) => <InvitationLink {...props} />,
  deleteAllPresentations: (props) => <DeleteAllPresentations {...props} />,
  deleteAllSlides: (props) => <DeleteAllSlides {...props} />,
  moveOrCopySlides: (props) => <MoveOrCopySlides {...props} />
}

interface MainModalProps {
  isOpen: boolean
  content: string | null
  type: string
  closeable: boolean
  data: Record<string, any>
}

const MainModal = ({ isOpen, content, type, closeable, data }: MainModalProps) => {
  const { closeModal } = useContext(MainModalContext)
  const location = useLocation()

  useEffect(() => {
    if (isOpen) {
      closeModal()
    }
  }, [location.pathname])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }

    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isOpen])

  const renderFullScreen = () => (
    <div className=" ReactModal__Overlay ReactModal__Overlay--after-open fullscreen-modal-bg">
      <div className="ReactModal__Content ReactModal__Content--after-open fullscreen-modal-body">
        {content ? contents[content](data) : null}
      </div>
    </div>
  )

  const renderMain = () =>
    isOpen ? (
      <div className="sc-modal">
        <div className="ReactModal__Overlay ReactModal__Overlay--after-open sc-modal-bg">
          <div className="fixed top-0 left-0 z-50 overflow-hidden outline-0 ReactModal__Content ReactModal__Content--after-open sc-modal-body">
            <div className="sc-modal-content">
              {closeable && (
                <button
                  className="absolute right-[30px] top-[17px] cursor-pointer z-30 w-[20px] h-[20px] text-[#6d6e71] hover:text-sc-collection-border-blue flex justify-center items-center"
                  data-testid="close-modal"
                  onClick={closeModal}
                >
                  <CancelIcon />
                </button>
              )}
              {content ? contents[content](data) : null}
            </div>
          </div>
        </div>
      </div>
    ) : null

  return type === "fullscreen" ? renderFullScreen() : renderMain()
}

export default MainModal
