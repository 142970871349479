import React from "react"
import { useHistory } from "react-router-dom"
import CategoryMenu from "@/components/category/CategoryMenu"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
const CategoryNavItem = ({
  navLink: { to, id, title, collectionsQty, presentationsQty },
  index,
  isActiveCategory,
  isEditModeActive,
  isActiveDrag
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  }
  const history = useHistory()

  const handleClick = () => {
    history.push(to.pathname)
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={`h-full flex mr-[23px] gap-1 items-center border-b ${
        isActiveCategory(id) && !isActiveDrag ? "border-scblue" : "border-transparent"
      }`}
      data-targetid={`category-${index}`}
      key={index}
      style={style}
    >
      <div className={"flex items-center"}>
        <div
          className={`hover:text-scblue text-sm ${isActiveCategory(id) && !isActiveDrag ? "text-scblue" : ""} ${
            isActiveDrag ? "text-[#D1D5DB]" : ""
          }`}
          data-testid="navigation-menu-item"
          onClick={handleClick}
          title={title}
        >
          {title}
        </div>
        {isEditModeActive && (
          <CategoryMenu
            categoryName={title}
            collectionsQty={collectionsQty}
            deleteModalTitle="Delete Page"
            id={id}
            isActive={isActiveCategory(to.category._id) && !isActiveDrag}
            order={to.category.order}
            presentationsQty={presentationsQty}
            renameModalTitle="Rename"
          />
        )}
      </div>
    </div>
  )
}

export default CategoryNavItem
