import slugify from "slug"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"
import Presentation from "../presentation/Presentation"
import AddCollectionButton from "./AddCollectionButton"
import { UserDataContext } from "../../hooks/UserDataHook"
import GlobalDropzone from "../utils/upload/GlobalDropzone"
import { HandleDropOptions } from "../utils/upload/types"
import { fileUpload } from "../utils/actions"
import PresentationPlaceholder from "../presentation/PresentationPlaceholder"
import { CategoryContext } from "@/context/CategoryContext"
import { MainModalContext } from "@/hooks/MainModalHook"
import { rectSortingStrategy, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { DragOverlay, useDroppable } from "@dnd-kit/core"
import { Ellipsis } from "@/components/utils/tooltip"
import SharedIndicator from "@/svg/SharedIndicator"
import LinkIndicator from "@/svg/LinkIndicator"
import AddSection from "@/components/category/AddSection"
import SubCategory from "@/components/category/SubCategory"
import Category from "@/components/modals/changeOrder/ChangeOrderCategory"
import { Batch } from "@/graphql/types/queries"
import { usePrevious } from "@/hooks/Previous"

export const GET_PRESENTATION = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      accessibleByUsers {
        _id
        firstName
        lastName
      }
      accessibleByUnits {
        _id
        name
      }
      accessibleByAll
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
        phash
      }
      category {
        _id
      }
    }
  }
`

const CollectionSection = ({
  isEditModeActive,
  id,
  name,
  Icon,
  relevantBatches = [],
  batchesName,
  active,
  category,
  isSection,
  openedBatch,
  setOpenedBatch,
  areBatchesHidden
}) => {
  const history = useHistory()

  const { user } = useContext(UserDataContext)
  const { addPresentation, loadCategories } = useContext(CategoryContext)
  const { openModal: openCollectionModal } = useContext(MainModalContext)
  const [uploadedEntityCount, setUploadedEntityCount] = useState(0)
  const { urlToken } = useParams()
  const [currentJobs, setCurrentJobs] = useState<Record<string, string>>({})
  const { teamOwner, role, cart } = user
  const filePickerInputRef = useRef<HTMLInputElement | null>(null)
  const prevBatches: any = usePrevious(relevantBatches)
  useEffect(() => {
    if (relevantBatches && prevBatches && relevantBatches.length >= prevBatches.length && uploadedEntityCount > 0) {
      setUploadedEntityCount(uploadedEntityCount - (relevantBatches.length - prevBatches.length))
    }
  }, [relevantBatches?.length])

  const [getPresentationData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATION, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (slides[0]?.state === "uploaded") {
        stopPresentationDataPolling()
      }
    }
  })

  const handleSetCurrentJobs = (jobs: Record<string, string>) => setCurrentJobs({ ...currentJobs, ...jobs })

  const handleDrop =
    ({
      getJob,
      getUploadFileUrl,
      setShowUploadProgress,
      setFilesToUpload,
      filesWithProgress,
      changeProgress
    }: HandleDropOptions) =>
    async (files: File[]) => {
      setUploadedEntityCount(files.length)
      setShowUploadProgress(true)

      const uploadPresentationJobs: Record<string, string> = {}

      const filePromises = files.map(async (file) => {
        const { data } = await getUploadFileUrl()
        await fileUpload(file, data.getUploadFileUrl.url, changeProgress)
        const {
          data: { createPresentation }
        } = await addPresentation(
          {
            variables: {
              fileToken: data.getUploadFileUrl.token,
              name: file.name.split(".")[0],
              category: id,
              icon: Icon
            }
          },
          isSection
        )

        if (createPresentation.success) {
          getPresentationData({ variables: { id: createPresentation.presentation.id } })

          uploadPresentationJobs[createPresentation.presentation.id] = createPresentation.job.id

          if (handleSetCurrentJobs) handleSetCurrentJobs(uploadPresentationJobs)
        }

        await getJob({
          variables: {
            queueName: "Slide",
            jobIds: [...Object.values(currentJobs), ...Object.values(uploadPresentationJobs)]
          }
        })
      })

      await Promise.all(filePromises)
      await loadCategories()
      setShowUploadProgress(false)
      setFilesToUpload({})

      filesWithProgress.current = {}
    }

  const openPresentationModal = () => filePickerInputRef.current?.click()

  const handleViewBatch = (batchUrlToken: string, pathName: string): void => {
    if (urlToken === batchUrlToken) {
      history.push(`/library/${category.urlToken}/${slugify(category.name)}`)
    } else {
      history.push(`/library/${batchUrlToken}/${slugify(pathName)}`)
    }
  }

  const { setNodeRef } = useDroppable({
    id
  })

  const {
    attributes,
    listeners,
    setNodeRef: newRef
  } = useSortable({
    id
  })

  const newAttributes = active?.id ? attributes : {}
  const newListeners = active?.id ? listeners : {}

  const requiresExtraTopMargin = relevantBatches.length || isEditModeActive

  const subCategories = useMemo(() => {
    return (
      category?.subCategories.filter((subCategory: typeof Category) =>
        batchesName === "Collections" ? subCategory.type === "collection" : subCategory.type === "presentation"
      ) || []
    )
  }, [category, batchesName])

  return (
    <>
      <SortableContext id={id} items={relevantBatches.map((batch) => batch.id)} strategy={rectSortingStrategy}>
        <div
          className={
            batchesName === "Collections"
              ? `grid gap-[20px] mobile-xs-cols-1 ${requiresExtraTopMargin ? "mt-[25px] " : ""} ${
                  cart
                    ? "tablet-md:grid-cols-2 tablet-xl:grid-cols-3 desktop-sm:grid-cols-4 desktop-xl:grid-cols-5 desktop-2xl:grid-cols-6 desktop-3xl:grid-cols-7 min-[2040px]:grid-cols-8"
                    : "mobile-sm:grid-cols-2 mobile-md:grid-cols-3 tablet-lg:grid-cols-4 tablet-xl:grid-cols-5 desktop-lg:grid-cols-6 desktop-xl:grid-cols-7 desktop-2xl:grid-cols-8 desktop-3xl:grid-cols-9"
                } ${areBatchesHidden ? "opacity-40" : ""} transition-opacity duration-200`
              : `grid gap-[20px] mobile-sm:grid-cols-1  w-full ${requiresExtraTopMargin ? "mt-[25px] " : ""} ${
                  cart
                    ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                    : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                } ${areBatchesHidden ? "opacity-40" : ""} transition-opacity duration-200`
          }
          ref={setNodeRef}
        >
          {relevantBatches?.map((batch: Batch, index: number) => {
            return (
              <Presentation
                actions={{ download: true, share: teamOwner, edit: false, remove: false }}
                active={urlToken === batch.urlToken}
                activeItem={active}
                batch={batch}
                handleViewBatch={handleViewBatch}
                id={batch.batchId}
                index={index}
                isFavourite={batch.isFavourite}
                key={batch.batchId}
                name={batch.name || "No given name"}
                refetch={() => {
                  getPresentationData({ variables: { id: batch.batchId } })
                }}
                type={
                  batchesName === "Collections"
                    ? "templates"
                    : teamOwner || role === "admin"
                    ? "batches"
                    : "sharedBatches"
                }
              />
            )
          })}
          {uploadedEntityCount > 0 &&
            Array.from(Array(uploadedEntityCount).keys()).map((index) => <PresentationPlaceholder key={index} />)}
          {isEditModeActive && (
            <>
              {!relevantBatches.length ? (
                <div ref={newRef} {...newListeners} {...newAttributes}>
                  {name === "Presentation" ? (
                    <div className="aspect-[490/357]">
                      <GlobalDropzone
                        handleDrop={handleDrop}
                        handlePicker={openPresentationModal}
                        isButton
                        ref={filePickerInputRef}
                        refetch={() => {}}
                      />
                    </div>
                  ) : (
                    <AddCollectionButton
                      handleAddCollection={() =>
                        openCollectionModal({
                          content: "addNewCategory",
                          data: {
                            isSection: isSection,
                            categoryId: id,
                            refetch: () => {
                              loadCategories()
                            }
                          }
                        })
                      }
                    />
                  )}
                </div>
              ) : name === "Presentation" ? (
                <div className="relative aspect-[490/357]">
                  <GlobalDropzone
                    handleDrop={handleDrop}
                    handlePicker={openPresentationModal}
                    isButton
                    ref={filePickerInputRef}
                    refetch={() => {}}
                  />
                </div>
              ) : (
                <AddCollectionButton
                  handleAddCollection={() =>
                    openCollectionModal({
                      content: "addNewCategory",
                      data: {
                        isSection: isSection,
                        categoryId: id,
                        refetch: () => {
                          loadCategories()
                        }
                      }
                    })
                  }
                />
              )}
            </>
          )}
        </div>
      </SortableContext>
      {active ? (
        active.icon ? (
          <DragOverlay>
            <div className="aspect-[181/192]">
              <div className="h-full group bg-white rounded-[3px] text-[#829FC0] shadow-[0_0_0_2px] shadow-sc-blue ">
                <div className="h-full flex flex-col">
                  <div className="h-1/2 flex justify-center items-end">
                    <div className="w-[25.6%] aspect-[46/51] flex">
                      <img alt={active.icon} src={`/icons/collectionIcons/blue/${active.icon}.svg`} />
                    </div>
                  </div>
                  <div className="h-[49.5$] p-[9.9%] w-full text-center font-bold text-[#0E2642] text-[15px]/[20px]">
                    <h2 className="h-full max-h-[2.9em] line-clamp-2 mobile-sm:max-h-[2.9em] mobile-sm:line-clamp-2 min-[1170px]:max-desktop-lg:max-h-[4em] min-[1170px]:max-desktop-lg:line-clamp-3 min-[1570px]:max-desktop-2xl:max-h-[4em] min-[1570px]:max-desktop-2xl:line-clamp-3 min-[1770px]:max-desktop-3xl:max-h-[4em] min-[1770px]:max-desktop-3xl:line-clamp-3 desktop-big:max-h-[4em] desktop-big:line-clamp-3">
                      {active.name}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </DragOverlay>
        ) : (
          <DragOverlay>
            <div className="aspect-[490/357]  ">
              <div className="h-full border border-sc-line flex flex-col">
                <div className="h-[77%] bg-[#e9eaed] relative">
                  {active.thumbUrl ? (
                    <picture className="flex h-full overflow-hidden">
                      <img
                        alt={active.name}
                        className="w-full object-cover"
                        src={active.thumbUrl.replace("{width}", "278")}
                      />
                    </picture>
                  ) : null}
                </div>
                <div className="bg-white flex-1 flex items-center px-[5.5%] gap-[3.6%] font-bold text-sc-text-dark">
                  <Ellipsis length={36} text={active.name} tooltip />
                  <SharedIndicator
                    value={
                      active.accessibleByAll || active.accessibleByUsers.length + active.accessibleByUnits.length > 0
                    }
                  />
                  <LinkIndicator value={active.sharedPresentationLinks?.isActive} />
                </div>
              </div>
            </div>
          </DragOverlay>
        )
      ) : null}
      {!isSection && !!subCategories.length && (
        <SortableContext
          id="subcategories"
          items={subCategories.map((sub) => `_${sub._id}`)}
          strategy={verticalListSortingStrategy}
        >
          {subCategories.map((subCategory: typeof Category, index: number) => {
            return (
              <SubCategory
                active={active}
                batchesName={batchesName}
                category={category}
                Icon={Icon}
                index={index}
                isEditModeActive={isEditModeActive}
                key={"sec-" + index}
                openedBatch={openedBatch}
                relevantBatches={relevantBatches}
                setOpenedBatch={setOpenedBatch}
                subCategory={subCategory}
                typeName={name}
              />
            )
          })}
        </SortableContext>
      )}
      {!isSection && isEditModeActive && <AddSection batchesName={batchesName} category={category} />}
    </>
  )
}

export default CollectionSection
