import { gql } from "@apollo/client"
import { fragments } from "./fragments"

// export const createPresentation = gql`
//   mutation createPresentation($data: PresentationInput!) {
//     createPresentation(data: $data) {
//       id
//       name
//     }
//   }
// `

// export const removeAllSlidesFromTemplate = gql`
//   mutation removeAllSlidesFromTemplate($batchId: String!) {
//     removeAllSlidesFromTemplate(batchId: $batchId) {
//       id
//     }
//   }
// `

// BU: new stuff end

export const login = gql`
  mutation login($email: String!, $password: String!, $timezoneOffset: Int) {
    login(email: $email, password: $password, timezoneOffset: $timezoneOffset)
  }
`

export const executePayPalSubscription = gql`
  mutation executePaypalSubscription($subscriptionId: String!, $payerId: String) {
    executePaypalSubscription(subscriptionId: $subscriptionId, payerId: $payerId)
  }
`

export const signUp = gql`
  mutation signUp($email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    signUp(email: $email, password: $password, firstName: $firstName, lastName: $lastName)
  }
`

export const confirmResetPassword = gql`
  mutation confirmResetPassword($userId: String!, $ott: String!, $newPassword: String!) {
    confirmResetPassword(userId: $userId, ott: $ott, newPassword: $newPassword) {
      success
      message
      email
    }
  }
`

export const resetPasswordInit = gql`
  mutation resetPasswordInit($email: String!) {
    resetPasswordInit(email: $email) {
      success
      message
    }
  }
`

export const loginWithGuestToken = gql`
  mutation loginWithGuestToken($guestToken: String!) {
    loginWithGuestToken(guestToken: $guestToken)
  }
`

export const acceptTeamOwnerInvitation = gql`
  mutation acceptTeamOwnerInvitation($token: String!, $password: String!, $firstName: String!, $lastName: String!) {
    acceptTeamOwnerInvitation(token: $token, password: $password, firstName: $firstName, lastName: $lastName)
  }
`

export const acceptOrganizationOwnerInvitation = gql`
  mutation acceptOrganizationOwnerInvitation(
    $token: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    acceptOrganizationOwnerInvitation(token: $token, password: $password, firstName: $firstName, lastName: $lastName)
  }
`

export const acceptOrganizationMemberInvitation = gql`
  mutation acceptOrganizationMemberInvitation($invitationId: String!, $token: String!) {
    acceptOrganizationMemberInvitation(invitationId: $invitationId, token: $token) {
      email
      status
    }
  }
`

export const removeMemberFromOrganization = gql`
  mutation removeMemberFromOrganization($userId: String!) {
    removeMemberFromOrganization(userId: $userId) {
      code
      success
      message
    }
  }
`

export const removeInvitation = gql`
  mutation removeInvitation($id: String!) {
    removeInvitation(id: $id) {
      id
    }
  }
`

export const setData = gql`
  mutation setData($email: String, $firstName: String, $lastName: String) {
    session {
      setData(email: $email, firstName: $firstName, lastName: $lastName) {
        id
        userId
        email
        firstName
        lastName
      }
    }
  }
`

export const setPassword = gql`
  mutation setPassword($oldPassword: String!, $newPassword: String!) {
    session {
      setPassword(oldPassword: $oldPassword, newPassword: $newPassword)
    }
  }
`

export const setBillingData = gql`
  mutation setBillingData($contactPerson: String, $email: String, $companyName: String, $companyDetails: String) {
    session {
      setBillingData(
        contactPerson: $contactPerson
        email: $email
        companyName: $companyName
        companyDetails: $companyDetails
      ) {
        userId
        billingData {
          contactPerson
          email
          companyName
          companyDetails
        }
      }
    }
  }
`

export const setBatchShared = gql`
  mutation setBatchShared($batchId: String!, $state: Boolean!) {
    session {
      setBatchShared(batchId: $batchId, state: $state) {
        id
        batchId
      }
    }
  }
`

export const createPaypalSubscription = gql`
  mutation createPaypalSubscription($planId: String!, $planAccessToken: String!) {
    session {
      createPaypalSubscription(planId: $planId, planAccessToken: $planAccessToken)
    }
  }
`

export const createStripeSubscription = gql`
  mutation createStripeSubscription($planId: String!, $planAccessToken: String!, $creditCardToken: String!) {
    session {
      createStripeSubscription(planId: $planId, planAccessToken: $planAccessToken, creditCardToken: $creditCardToken) {
        userId
      }
    }
  }
`

export const buyBlueprintsWithStripe = gql`
  mutation buyBlueprintsWithStripe($blueprintIds: [String!]!, $creditCardToken: String!) {
    session {
      buyBlueprintsWithStripe(blueprintIds: $blueprintIds, creditCardToken: $creditCardToken)
    }
  }
`

export const cancelSubscription = gql`
  mutation cancelSubscription($subscriptionId: String!) {
    session {
      cancelSubscription(subscriptionId: $subscriptionId) {
        userId
        subscription {
          id
          subscriptionId
          payedTo
          willBeCanceled
          plan {
            id
            planId
            name
            amount
          }
        }
      }
    }
  }
`

export const setCancellationSurveyFeedback = gql`
  mutation setCancellationSurveyFeedback($subscriptionId: String!, $feedback: String!) {
    session {
      setCancellationSurveyFeedback(subscriptionId: $subscriptionId, feedback: $feedback)
    }
  }
`

// TODO: BU: not used anywhere... check if can be deleted from sc-api
export const reindexBatchLinks = gql`
  mutation reindexLinks($batchId: String!) {
    session {
      reindexLinks(batchId: $batchId)
    }
  }
`

export const removeBatch = gql`
  mutation removeBatch($batchId: String!) {
    session {
      removeBatch(batchId: $batchId)
    }
  }
`

export const removeArchivedBatches = gql`
  mutation {
    removeArchivedBatches
  }
`

export const updateSlidesInCart = gql`
  mutation updateSlidesInCart($slides: [SlideInput!]!) {
    updateSlidesInCart(slides: $slides) {
      cart {
        ...fields
      }
    }
  }
  ${fragments.presentation}
`

export const updateStripeSubscription = gql`
  mutation updateStripeSubscription($subscriptionId: String!, $creditCardToken: String!) {
    session {
      updateStripeSubscription(subscriptionId: $subscriptionId, creditCardToken: $creditCardToken) {
        subscriptionId
      }
    }
  }
`

export const inviteTeamOwner = gql`
  mutation inviteTeamOwnerToOrganization($groupId: String!, $email: String!, $showBar: Boolean!) {
    inviteTeamOwnerToOrganization(groupId: $groupId, email: $email, showBar: $showBar) {
      code
      success
      message
    }
  }
`

export const initGodMode = gql`
  mutation initGodMode($emailOrId: String!) {
    admin {
      createSession(emailOrId: $emailOrId)
    }
  }
`

export const createPlan = gql`
  mutation createPlan($data: CreatePlanInput!) {
    admin {
      createPlan(data: $data) {
        id
      }
    }
  }
`

export const updatePlan = gql`
  mutation updtaePlan($data: UpdatePlanInput!, $planId: String!) {
    admin {
      updatePlan(planId: $planId, data: $data) {
        id
      }
    }
  }
`

export const createGroup = gql`
  mutation createGroup($data: CreateGroupInput!) {
    admin {
      createGroup(data: $data) {
        id
        groupId
        plans {
          id
          name
          amount
          interval
        }
        urlPrefix
        searchTags
        name
        custom
      }
    }
  }
`

export const updateGroup = gql`
  mutation updateGroup($data: UpdateGroupInput!, $groupId: String!) {
    admin {
      updateGroup(groupId: $groupId, data: $data) {
        id
      }
    }
  }
`

export const startTrial = gql`
  mutation startTrial($userId: String!, $endDate: String!, $planId: String!) {
    admin {
      createManualSubscription(userId: $userId, endDate: $endDate, planId: $planId) {
        id
      }
    }
  }
`

export const updateTeamTemplateBatch = gql`
  mutation updateTeamTemplateBatch($batchId: String, $data: TeamBatchUpdateInput!) {
    session {
      updateTeamTemplateBatch(batchId: $batchId, data: $data) {
        id
        batchId
        name
        icon
      }
    }
  }
`

export const uploadIntoTemplate = gql`
  mutation uploadIntoTemplate($batchId: String!, $uploadedFileToken: String!, $position: Int!) {
    session {
      uploadIntoTemplate(batchId: $batchId, uploadedFileToken: $uploadedFileToken, position: $position) {
        id
        batchId
        name
        icon
      }
    }
  }
`

export const ADD_SLIDE = gql`
  mutation addSlide($id: String!, $files: [SlideFile!]!, $position: Float!) {
    addSlide(id: $id, files: $files, position: $position) {
      code
      success
      message
      job {
        id
        queueName
        lifecycle {
          status
          error
        }
      }
      presentation {
        id
        name
        progress
      }
    }
  }
`

// // # TODO: delete me asap (and every use in code)
// export const replaceSlide = gql`
//   mutation replaceSlide($batchId: String!, $uploadedFileToken: String!, $position: Int!) {
//     session {
//       replaceSlide(batchId: $batchId, uploadedFileToken: $uploadedFileToken, position: $position) {
//         id
//       }
//     }
//   }
// `

export const updateUnit = gql`
  mutation updateUnit($unitId: String!, $data: UpdateUnitData!) {
    session {
      updateUnit(unitId: $unitId, data: $data) {
        id
      }
    }
  }
`

export const removeUnit = gql`
  mutation removeUnit($unitId: String!) {
    removeUnit(unitId: $unitId) {
      code
      success
      message
    }
  }
`

export const removeUserFromUnit = gql`
  mutation removeUnitMember($unitId: String!, $memberId: String!) {
    removeUnitMember(unitId: $unitId, memberId: $memberId) {
      code
      success
      message
    }
  }
`

// TODO: BU: _rewrite_me
export const autoCreateBatches = gql`
  mutation autoCreateBatches($groupId: String!, $sets: [SetOfSlidesInput!]!) {
    admin {
      autoCreateBatches(groupId: $groupId, sets: $sets)
    }
  }
`

export const changeTeamOwner = gql`
  mutation changeTeamOwner($currentTeamOwnerId: String!, $newTeamOwnerId: String!) {
    admin {
      changeTeamOwner(currentTeamOwnerId: $currentTeamOwnerId, newTeamOwnerId: $newTeamOwnerId) {
        id
      }
    }
  }
`

export const deleteUser = gql`
  mutation deleteUser($userEmailOrId: String!) {
    admin {
      deleteUser(userEmailOrId: $userEmailOrId)
    }
  }
`

export const reorderSlides = gql`
  mutation reorderSlides($presentationId: String!, $activeId: String!, $overId: String!, $movingIds: [String!]!) {
    reorderSlides(presentationId: $presentationId, activeId: $activeId, overId: $overId, movingIds: $movingIds) {
      code
      success
      message
      job {
        id
        queueName
      }
    }
  }
`

export const addNewFiles = gql`
  mutation addNewFiles($batchId: String!, $sets: [SetOfSlidesInput!]!) {
    admin {
      addNewFilesToTemplateBatch(batchId: $batchId, sets: $sets) {
        id
      }
    }
  }
`

export const MERGE_SLIDES = gql`
  mutation mergeSlides($failure: Boolean = false, $id: String!) {
    mergeSlides(failure: $failure, id: $id) {
      code
      success
      message
      job {
        id
        queueName
      }
    }
  }
`

export const ADD_EVENT = gql`
  mutation addEvent($type: EventEnum!, $browserFingerprint: String!, $data: JSON) {
    addEvent(type: $type, browserFingerprint: $browserFingerprint, data: $data) {
      code
      success
      message
      event {
        _id
        type
        browserFingerprint
        data
      }
    }
  }
`
