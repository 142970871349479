import React, { useContext } from "react"
import { useClickOutside } from "@/hooks/ClickOutsideHook"
import { MainModalContext } from "@/hooks/MainModalHook"
import { copyToClipboard } from "../utils/clipboard"
import { Link } from "react-router-dom"
import slugify from "slug"

const SlideDropdownList = ({
  slide,
  batchId,
  closeDropdown,
  handleDownload,
  deleteAvailable,
  moveToAvailable,
  editInPowerPointAvailable,
  goToSlideAvailable,
  openConfirmationModal,
  getThumbAndLock,
  downloadAvailable
}) => {
  const ref = closeDropdown ? useClickOutside(closeDropdown) : {}
  const { openModal } = useContext(MainModalContext)
  const slideBatch = slide.presentations?.[0]

  return (
    <ul
      className="*:p-[6px] *:pl-[13px] *:pr-[10px] hover:*:bg-[#c6cbce4d] hover:*:text-[#3390ff] text-sm text-left *:cursor-pointer"
      data-testid="slide-context-menu"
      ref={(_ref) => {
        ref.current = _ref
      }}
    >
      <li className={`${!downloadAvailable ? "pointer-events-none opacity-50" : ""}`} onClick={handleDownload}>
        Download
      </li>
      {deleteAvailable && <li onClick={openConfirmationModal}>Delete</li>}
      {editInPowerPointAvailable && (
        <li
          onClick={() => {
            const base64jwtToken = btoa(localStorage.getItem("jwtToken"))
            const base64batchId = btoa(batchId)
            const base64slideId = btoa(slide.blueprintId)
            const random5token = Math.random().toString(36).slice(-5)

            // BU: not the most elegant, but it works...
            if (!window.Cypress) {
              window.location.href = `ms-powerpoint:ofe|u|${
                import.meta.env.VITE_SC_WEBDAV_PATH
              }/${base64jwtToken}/${base64batchId}/${base64slideId}/${random5token}.pptx`
            }

            getThumbAndLock({ variables: { slideId: slide.blueprintId } })

            closeDropdown()
          }}
        >
          Edit in PowerPoint
        </li>
      )}
      {moveToAvailable && (
        // TODO: BU: if only one category, this should be disabled
        <li
          onClick={() =>
            openModal({
              content: "moveOrCopySlides",
              data: {
                presentationIds: [batchId],
                blueprintIds: [slide.blueprintId],
                isCopyToModal: false
              }
            })
          }
        >
          Move to
        </li>
      )}
      {goToSlideAvailable && (
        <li className="!p-0">
          <Link
            className="inline-block w-full hover:text-inherit p-[6px] pl-[13px] pr-[10px]"
            to={
              slideBatch.isMyPresentation
                ? `/my-presentations/${slideBatch.urlToken}/${slugify(slideBatch.name)}/${slide.id}`
                : `/library/${slideBatch.urlToken}/${slugify(slideBatch.name)}/${slide.id}`
            }
          >
            Go to slide
          </Link>
        </li>
      )}
      {import.meta.env.DEV && (
        <li
          onClick={() => {
            copyToClipboard(slide.blueprintId)
          }}
        >
          <small>{slide.blueprintId}</small>
        </li>
      )}
    </ul>
  )
}

export default SlideDropdownList
