import React, { useContext, useEffect, useRef } from "react"
import { CategoryContext } from "@/context/CategoryContext"
import { CancelIcon, CheckMarkIcon } from "@/svg/SvgIcons"

const AddPresentationForm = ({ input, setInput }) => {
  const { addPresentation } = useContext(CategoryContext)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }, [inputRef])

  const handleCreatePresentation = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!input) return

    addPresentation(
      {
        variables: {
          name: input.value,
          icon: input.type === "collection" ? "Presentation" : null,
          category: input.categoryId,
          insertAtBeginning: true,
          state: "uploaded"
        }
      },
      input.isSubCategory
    )
    closeInput()
  }

  const closeInput = () => {
    setInput(null)
  }

  return (
    <form className={"flex items-center gap-2 py-1"}>
      <input
        className="flex w-full rounded-md border px-3 py-2 text-sm"
        onChange={(e) => setInput({ ...input, value: e.target.value })}
        placeholder={`New ${input.type} name`}
        ref={inputRef}
        value={input.value}
      />
      <button
        className={`px-[12px] rounded-md h-9 flex items-center ${
          !input.value.length ? "text-sc-light-font" : "text-inherit bg-[#e9ecef] hover:text-[#3390ff]"
        }`}
        disabled={!input.value.length}
        onClick={handleCreatePresentation}
        type="submit"
      >
        <CheckMarkIcon visible />
      </button>
      <button className="px-[12px] hover:text-[#3390ff]" onClick={closeInput}>
        <CancelIcon />
      </button>
    </form>
  )
}

export default AddPresentationForm
