import { useQuery, gql } from "@apollo/client"
import { useContext, useEffect, useState } from "react"
import Presentation from "@/components/presentation/Presentation"
import Slide from "@/components/slide/Slide"
import { FavoritesEmptySvg } from "@/svg/EmptyContainerSvgs"
import { ErrorPlaceholder, MainLoader } from "@/components/utils/placeholders"
import { UserDataContext } from "@/hooks/UserDataHook"
import { CategoryContext } from "@/context/CategoryContext"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"
import PowerPointFavourites from "./PowerPointFavourites"
import SlideSelectBar from "@/components/slide/SlideSelectBar"

export const FAVOURITES = gql`
  query getFavourites {
    getFavourites {
      presentations {
        id
        batchId
        name
        icon
        isFavourite
        labels
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        thumbUrl
        progress
        deleted
        uploaded
        mode
        creator {
          id
          firstName
          lastName
        }
        category {
          _id
        }
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          isFavourite
        }
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        state
        isFavourite
        presentations {
          id
          _id
        }
      }
    }
  }
`

const FavouritesContainer = () => {
  const favoritesTabs = {
    slides: "slides",
    batches: "batches"
  }
  const { loadCategories } = useContext(CategoryContext)
  const { user } = useContext(UserDataContext)
  const [openedBatch, setOpenedBatch] = useState(null)
  const [activeTab, setActiveTab] = useState(favoritesTabs.batches)
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const [selectedSlideIds, setSelectedSlideIds] = useState([])

  const {
    data: batchData,
    loading,
    error,
    refetch
  } = useQuery(FAVOURITES, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (batchData && batchData.getFavourites?.presentations?.filter((b) => b.isFavourite).length === 0) {
      setActiveTab("slides")
    } else if (batchData && batchData.getFavourites?.slides?.filter((s) => s.isFavourite).length === 0) {
      setActiveTab("batches")
    }
  }, [batchData])

  useEffect(() => {
    setSelectedSlideIds([])
  }, [activeTab])

  if (loading) return <MainLoader />
  if (error) return <ErrorPlaceholder />

  let { id, subscription, showBar, teamOwner, presentations, slides } = batchData.getFavourites

  const { cart } = user

  const filteredSlides = slides.filter((s) => s.isFavourite)
  const filteredPresentations = presentations.filter((b) => b.isFavourite)

  const handleSelectSlide = (id) => {
    const isSelected = selectedSlideIds.includes(id)
    setSelectedSlideIds(isSelected ? selectedSlideIds.filter((slideId) => slideId !== id) : [...selectedSlideIds, id])
  }

  return (
    <div
      className={`flex justify-center ${
        isInsidePowerPoint
          ? "h-full mobile-xs:px-[20px]"
          : cart
          ? "tablet-xl:px-[60px]"
          : "tablet-sm:px-[60px] min-h-empty-h mobile-xs:px-[24px] py-[43px]"
      }`}
    >
      {filteredPresentations.length + filteredSlides.length === 0 ? (
        <div className="flex flex-col items-center justify-center text-center">
          <FavoritesEmptySvg />
          <h2 className="text-[25px] text-sc-blue font-bold">NO FAVORITE SLIDES YET</h2>
          <p className="mt-[15px] mb-[60px] font-light">
            Keep track of the slides you've liked. <br />
            To favour a slide, click the heart when hovering over it.
          </p>
        </div>
      ) : (
        <div className="w-full desktop-big:w-[1812px]">
          {isInsidePowerPoint ? (
            <PowerPointFavourites
              presentations={filteredPresentations}
              refetch={() => {
                refetch()
                loadCategories()
              }}
              slides={filteredSlides}
            />
          ) : (
            <>
              {!!selectedSlideIds.length && (
                <div className={"sticky top-[20px] z-10"}>
                  <div className="absolute -top-[10px] left-1/2 -translate-x-1/2 rounded-[30px] shadow-gradient">
                    <SlideSelectBar
                      allSlides={filteredSlides}
                      refetch={refetch}
                      selectedSlideIds={selectedSlideIds}
                      setSelectedSlideIds={setSelectedSlideIds}
                    />
                  </div>
                </div>
              )}
              <ul className="flex mx-[6.25px] gap-[16px]">
                <li>
                  <p>Filter results:</p>
                </li>
                {
                  <li
                    className={`group flex ${
                      activeTab === "batches" ? "font-bold text-sc-blue border-b border-sc-blue hover:opacity-80" : ""
                    } ${
                      filteredPresentations.length === 0 ? "cursor-not-allowed" : "cursor-pointer hover:text-sc-blue"
                    }`}
                    onClick={
                      filteredPresentations.length === 0
                        ? undefined
                        : () => {
                            setActiveTab("batches")
                          }
                    }
                  >
                    <p title="Presentations">Presentations</p>
                    <span
                      className={`${filteredPresentations.length !== 0 ? "group-hover:text-sc-blue" : ""} ${
                        activeTab === "batches" ? "font-bold text-sc-blue" : "text-[#9da3b2]"
                      }`}
                    >
                      ({filteredPresentations.length})
                    </span>
                  </li>
                }

                {
                  <li
                    className={`group flex ${
                      activeTab === "slides" ? "font-bold text-sc-blue border-b border-sc-blue hover:opacity-80" : ""
                    } ${filteredSlides.length === 0 ? "cursor-not-allowed" : "cursor-pointer hover:text-sc-blue"}`}
                    onClick={
                      filteredSlides.length === 0
                        ? undefined
                        : () => {
                            setActiveTab("slides")
                          }
                    }
                  >
                    <p title="Slides">Slides </p>
                    <span
                      className={`${filteredSlides.length !== 0 ? "group-hover:text-sc-blue" : ""} ${
                        activeTab === "slides" ? "font-bold text-sc-blue" : "text-[#9da3b2]"
                      }`}
                    >
                      ({filteredSlides.length})
                    </span>
                  </li>
                }
              </ul>

              {activeTab === "batches" ? (
                <div
                  className={`mt-[25px] grid gap-[20px] mobile-sm:grid-cols-1 w-full ${
                    cart
                      ? "desktop-sm:grid-cols-2 desktop-xl:grid-cols-3 desktop-3xl:grid-cols-4"
                      : "tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                  }`}
                >
                  {filteredPresentations.map((batch) => (
                    <Presentation
                      actions={{
                        download: true,
                        share: !!teamOwner,
                        edit: false,
                        remove: false
                      }}
                      active={batch.batchId === openedBatch?.id}
                      batch={batch}
                      id={batch.batchId}
                      isFavourite={batch.isFavourite}
                      isSubscription={!!subscription}
                      isTeamOwner={teamOwner}
                      key={batch.batchId}
                      name={batch.name || "No given name"}
                      refetch={() => {
                        refetch()
                        loadCategories()
                      }}
                      setOpenedBatch={setOpenedBatch}
                      showBar={{ showBar, id }}
                      type="favourites"
                    />
                  ))}
                </div>
              ) : (
                <div
                  className={`grid mt-[25px] gap-[15px] mobile-sm:grid-cols-1 ${
                    cart
                      ? "tablet-xl:grid-cols-2 desktop-md:grid-cols-3 desktop-2xl:grid-cols-4 desktop-big:grid-cols-5"
                      : "mobile-md:grid-cols-2 tablet-lg:grid-cols-3 desktop-lg:grid-cols-4 desktop-2xl:grid-cols-5"
                  }`}
                >
                  {filteredSlides.map((slide, index) => (
                    <Slide
                      batch={{ slides }}
                      handleSelectSlide={handleSelectSlide}
                      index={index}
                      isSelected={selectedSlideIds.includes(slide.id)}
                      isSelectMode={!!selectedSlideIds.length}
                      key={slide.id}
                      noActions
                      refetch={() => {
                        refetch()
                        loadCategories()
                      }}
                      slide={slide}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default FavouritesContainer
