import SharedIndicator from "@/svg/SharedIndicator"
import LinkIndicator from "@/svg/LinkIndicator"
import ContextMenu from "@/components/presentation/ContextMenu"
import SlidePlaceholder from "@/components/slide/SlidePlaceholder"
import React, { useContext, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import slugify from "slug"
import { UserDataContext } from "@/hooks/UserDataHook"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

const ThumbnailPresentation = ({
  active,
  batch,
  getSlidesAndLock,
  handleClick,
  handleLike,
  isEditModeActive,
  hideTools,
  isFavourite,
  name,
  refetch,
  setModalIsOpened,
  type,
  setThumbUrl,
  isEmpty,
  isSearchPage
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const { user } = useContext(UserDataContext)

  const slidesLength = batch.slides.length
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const location = useLocation()
  const isDashboardPage = location.pathname === "/dashboard"
  const handlePropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }

  return (
    <div className="group h-full">
      {isSearchPage ? (
        <div className={`flex flex-col cursor-default ${isInsidePowerPoint ? "gap-[10px]" : "gap-[17px]"} `}>
          <div
            className={`h-full relative ${isInsidePowerPoint ? "rounded-[4px]" : "border border-sc-line"}`}
            data-testid="presentation-toggle"
            onClick={handleClick}
            role="button"
            tabIndex={0}
          >
            <div
              className={`opacity-0 border-2 transition ease-in-out hover:opacity-100 absolute top-0 w-full h-full text-center ${
                isInsidePowerPoint
                  ? "rounded-[4px] border-sc-blue duration-[200ms]"
                  : "border-transparent bg-sc-overlay-light duration-700"
              } ${active || isDropdownOpened ? "opacity-100" : ""}`}
            >
              <div className="absolute top-0 w-full py-[12px] px-[14px] flex justify-end">
                <div
                  className="group/menu flex items-center justify-center text-sc-font-gray bg-white px-[5px] rounded-[50px]"
                  onClick={handlePropagation}
                >
                  {!hideTools && (
                    <button
                      className="mobile-sm:w-[27px] mobile-sm:h-[32px] mobile-sm:opacity-100 mobile-sm:py-[4px] mobile-sm:px-[8px] w-0 h-0 opacity-0 group-hover/menu:opacity-100 group-hover/menu:w-[27px] group-hover/menu:h-[32px] group-hover/menu:py-[4px] group-hover/menu:px-[8px] flex items-center justify-center px[8px] transition-colors ease-in-out duration-200"
                      data-testid="btn-heart"
                      onClick={handleLike}
                    >
                      <svg
                        className={`${
                          isFavourite ? "w-full fill-sc-blue animate-heart-beat" : "w-full fill-sc-font-gray"
                        } hover:fill-sc-blue`}
                        data-testid={`${isFavourite ? "heart-beat" : "heart-unbeat"}`}
                        viewBox="0 0 13 14"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.03146 2.06531L6.49811 2.63947L5.96306 2.0648C4.59895 0.599519 2.38724 0.599519 1.02311 2.0648C-0.341035 3.53007 -0.341035 5.90575 1.02311 7.37104L6.15413 12.8824C6.34448 13.0869 6.6531 13.0869 6.84345 12.8824L11.9784 7.37C13.3395 5.89988 13.3419 3.53086 11.9775 2.06531C10.6109 0.59737 8.39809 0.59737 7.03146 2.06531ZM11.2872 6.63166L6.49876 11.7719L1.71241 6.63061C0.728951 5.57424 0.728951 3.86156 1.71241 2.8052C2.69586 1.74883 4.29034 1.74883 5.2738 2.8052L6.15595 3.75278C6.34955 3.96071 6.66454 3.95665 6.8534 3.74381L7.72079 2.80572C8.70672 1.74669 10.3022 1.74669 11.2882 2.80572C12.2719 3.86234 12.2702 5.56998 11.2872 6.63166Z" />
                        <path
                          className={isFavourite ? "fill-sc-blue" : "fill-transparent"}
                          d="M7 12.4643L1.5 6.46429L1 5.46429V3.46429L2.5 1.96429L4 1.46429L5.5 1.96429L7 3.46429L7.5 2.46429L9.5 1.46429L11.5 1.96429L13 3.96429L12.5 6.46429L7 12.4643Z"
                        />
                      </svg>
                    </button>
                  )}
                  {!isInsidePowerPoint && (
                    <div className="w-[27px] h-[32px] flex items-center justify-center px-[8px] py-[4px] ">
                      <ContextMenu
                        batch={batch}
                        getSlidesAndLock={getSlidesAndLock}
                        isOpened={isDropdownOpened}
                        isSearchPage={isSearchPage}
                        refetch={refetch}
                        setIsOpened={setIsDropdownOpened}
                        setModalIsOpened={setModalIsOpened}
                        type={type}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {batch.thumbUrl ? (
              <picture className={`flex h-full overflow-hidden ${isInsidePowerPoint ? "rounded-[4px]" : ""}`}>
                <img
                  alt={batch.name}
                  className="w-full object-cover aspect-[427/240.5]"
                  data-testid="presentation-thumbnail"
                  onError={() => setThumbUrl(null)}
                  src={`${batch.thumbUrl.replace("{width}", "278")}`}
                  srcSet={`${batch.thumbUrl.replace("{width}", "556")} 2x`}
                  style={{ imageRendering: "auto" }}
                />
              </picture>
            ) : (
              <div className="flex justify-center items-center aspect-[427/240.5]">
                {!isEmpty && <SlidePlaceholder />}
              </div>
            )}
          </div>

          <div
            className={`flex-1 flex flex-col pr-[5.5%] font-bold text-sc-text-dark ${
              isInsidePowerPoint ? "gap-[4px] font-semibold" : "gap-[7px] "
            }`}
          >
            <div className="flex gap-[14px]">
              <span
                className={`${isInsidePowerPoint ? "text-[12px] truncate" : "truncate"}`}
                data-testid="presentation-name"
                title={name}
              >
                <Link
                  to={{
                    pathname: isInsidePowerPoint
                      ? `/dashboard/${batch.urlToken}/${slugify(batch.name)}`
                      : batch.isMyPresentation
                      ? `/my-presentations/${batch.urlToken}/${slugify(batch.name)}`
                      : `library/${batch.urlToken}/${slugify(batch.name)}`,
                    state: isInsidePowerPoint ? { slides: batch.slides, currentPathname: batch.name } : null
                  }}
                >
                  {name}
                </Link>
              </span>
              {isEditModeActive && !isInsidePowerPoint && batch.accessibleByUsers && batch.accessibleByUnits && (
                <SharedIndicator
                  value={batch.accessibleByAll || batch.accessibleByUsers.length + batch.accessibleByUnits.length > 0}
                />
              )}
              {!isInsidePowerPoint && <LinkIndicator value={batch?.sharedPresentationLinks?.isActive} />}
            </div>
            <div
              className={`flex gap-[7px] items-center whitespace-nowrap ${
                isInsidePowerPoint ? "text-[11px] text-[#717171] font-normal" : "text-[13px] text-[#A5A8AF]"
              } `}
            >
              {(!isInsidePowerPoint || (!isDashboardPage && !isSearchPage)) && (
                <>
                  <span>
                    {slidesLength} slide{slidesLength !== 1 ? "s" : ""}
                  </span>
                  <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.06494" cy="2" fill="#A5A8AF" r="1.5" />
                  </svg>
                  <span className="overflow-hidden text-ellipsis">
                    {user.userId === batch.creator.id ? "You" : `${batch.creator.firstName} ${batch.creator.lastName}`}
                  </span>
                  <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.06494" cy="2" fill="#A5A8AF" r="1.5" />
                  </svg>
                </>
              )}
              <span>
                {new Date(batch.updatedAt).toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric"
                })}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full border border-sc-line flex flex-col">
          <div className="h-[77%] relative">
            <div
              className={`opacity-0 hover:opacity-100 transition ease-in-out duration-700 absolute top-0 bg-sc-overlay w-full h-full text-center ${
                active || isDropdownOpened ? "opacity-100" : ""
              }`}
            >
              {!hideTools && (
                <button
                  className="w-[8%] aspect-square absolute top-[25px] left-[25px] flex justify-center items-center border border-white rounded-[3px] hover:bg-[#ffffff4d] transition-colors ease-in-out duration-200"
                  data-testid="btn-heart"
                  onClick={handleLike}
                >
                  <svg
                    className={`w-[52.5%] ${
                      isFavourite ? "fill-white animate-heart-beat" : "fill-transparent"
                    } stroke-white`}
                    data-testid={`${isFavourite ? "heart-beat" : "heart-unbeat"}`}
                    viewBox="0 0 18 15.4"
                  >
                    <path
                      d="M2,1.7C2.3,1.3,2.8,1,3.3,0.8C5.5-0.1,8,0.9,9,3c1-2.1,3.5-3.1,5.7-2.2c2.3,0.9,3.4,3.5,2.5,5.8
                  c-0.5,1.1-2.4,3.1-4.3,4.8L12.3,12c-1.6,1.5-3.1,2.7-3.3,2.9c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0s0,0,0,0c-0.4-0.3-7.3-6-8.2-8.2
                  C0.2,5.1,0.5,3.5,1.4,2.2L2,1.7z"
                    />
                  </svg>
                </button>
              )}
              <div className="h-full flex justify-center items-center">
                <button
                  className="h-[17.3%] w-[48.6%] text-[14.5px] border border-[#e9eaed] text-white rounded-[30px] hover:bg-[#ffffff4d] transition-colors ease-in-out duration-200"
                  data-testid="presentation-toggle"
                  onClick={handleClick}
                  type="button"
                >
                  {active ? "Close Presentation" : "View Presentation"}
                </button>
              </div>
              <ContextMenu
                batch={batch}
                getSlidesAndLock={getSlidesAndLock}
                isOpened={isDropdownOpened}
                refetch={refetch}
                setIsOpened={setIsDropdownOpened}
                setModalIsOpened={setModalIsOpened}
                type={type}
              />
            </div>
            {batch.thumbUrl ? (
              <picture className="flex h-full overflow-hidden">
                <img
                  alt={batch.name}
                  className="w-full object-cover"
                  data-testid="presentation-thumbnail"
                  onError={() => setThumbUrl(null)}
                  src={`${batch.thumbUrl.replace("{width}", "278")}`}
                  srcSet={`${batch.thumbUrl.replace("{width}", "556")} 2x`}
                  style={{ imageRendering: "auto" }}
                />
              </picture>
            ) : (
              <div className="bg-[#e9eaed] flex justify-center items-center h-full">
                {!isEmpty && <SlidePlaceholder />}
              </div>
            )}
          </div>
          <div className="bg-white flex-1 flex items-center px-[5.5%] gap-[3.6%] font-bold text-sc-text-dark">
            <span className="truncate" data-testid="presentation-name" title={name}>
              {name}
            </span>
            {isEditModeActive && batch.accessibleByUsers && batch.accessibleByUnits && (
              <SharedIndicator
                value={batch.accessibleByAll || batch.accessibleByUsers.length + batch.accessibleByUnits.length > 0}
              />
            )}
            <LinkIndicator value={batch?.sharedPresentationLinks?.isActive} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ThumbnailPresentation
